import useBrandInfo from "@hooks/dataHooks/useBrandInfo"
import getCanonicalURL from "@lib/getCanonicalURL"
import * as React from "react"

// eslint-disable-next-line import/no-unresolved
const { useLocation } = require("@reach/router")

function Seo({ description, title, children, img, customCanonical }) {
  const { name } = useBrandInfo()

  // const { nextURL, prevURL } = getCanonicalURL(customCanonical)
  let { pathname } = useLocation()

  // const canonical = useCanonicalURL()

  if (pathname === "/home/" || pathname === "/home") {
    pathname = "/"
  }

  let currentUrl = ""
  currentUrl = customCanonical ? customCanonical : process.env.GATSBY_SITE_URL + pathname

  // check trailing slash
  if (!currentUrl.endsWith("/")) currentUrl += "/"

  // remove page- from url
  if (currentUrl.includes("page-")) {
    currentUrl = currentUrl
      .split("/")
      .filter((path) => !path.includes("page-"))
      .join("/")
  }

  // remove multiple slashes
  currentUrl = currentUrl.replace(/([^:]\/)\/+/g, "$1")

  // set lowercase url
  currentUrl = currentUrl.toLowerCase()

  const metaDescription = description || `${name} is a real estate company.`

  let robots = "all"
  if (process.env.GATSBY_SITE_ENV !== "production") {
    robots = "none"
  }

  return (
    <>
      <title>{title ? `${title} | ${name}` : name}</title>
      <link rel="canonical" href={currentUrl} />
      {/* {prevURL && <link rel="prev" href={prevURL} />}
      {nextURL && <link rel="next" href={nextURL} />} */}
      {/* <link
        // eslint-disable-next-line react/no-invalid-html-attribute
        rel="image_src"
        href={img || "https://www.providentestate.com/images/favicon.png"}
      /> */}
      <meta name="robots" content={robots} />
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      {img && (
        <>
          <meta name="image" property="og:image" content={img} />
          <meta property="og:image:secure_url" content={img} />
          <meta property="og:image:url" content={img} />
          <meta property="og:image:type" content="image/jpeg" />
          {/* <meta property="og:image:width" content="600" />
          <meta property="og:image:height" content="400" /> */}
          <meta
            property="og:image:alt"
            content={title ? `${title} | ${name}` : name}
          />
          <meta name="twitter:image" content={img} />
        </>
      )}
      {!img && <meta 
      name="image"
        property="og:image"
        content={img || "https://www.providentestate.com/icons/icon-512x512.png"}
      />}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="providentestate.com" />
      <meta name="twitter:creator" content={name || ""} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta property="twitter:image" content={img} />
      <meta name="twitter:url" content={currentUrl} />
      <meta
        name="facebook-domain-verification"
        content="cp7an7etkenc536pg3tyuy003m88wk"
      />
      <meta name="google-adsense-account" content="ca-pub-7489126416775423" />
      {children}
      <link rel="preconnect" href="https://dev.visualwebsiteoptimizer.com" />
      <script charset="UTF-8" src="//web.webpushs.com/js/push/0d71181b0e29c44b601567140a3eaf5c_1.js" async></script>

      </>
  )
}

export default Seo
