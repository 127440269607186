import React, { useEffect, useState } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { isEmpty } from "lodash"
import CTALink from "@components/elements/CTALink"
import "./BannerMenu.scss"
const BannerMenu = ({color}) => {
    const data = useStaticQuery(graphql`
        query {
          strapiGlobalMenu {
            header_links {
              id
              main_menu {
                ...ctaFragment
              }
              sub_menu_cta {
                ...ctaFragment
              }
            }
          }
        }
      `)
    const { header_links } = data.strapiGlobalMenu

    return (
        <div className={`mobile-banner-menu ${color}`}>
            <div className="scroll-i d-flex d-md-none">
                {!isEmpty(header_links) &&
                    header_links.map((item, mainindex) => {
                        const { main_menu, id } = item
                        return (

                            main_menu.cta_label !== "More" &&
                            <CTALink
                                key={main_menu.id}
                                cta={main_menu}
                                className="main-menu"
                                alsoLabel
                                reload={mainindex <= 2 ? true: false}

                            />
                        )
                    })
                }
            </div>
        </div>

    )
}

export default BannerMenu
